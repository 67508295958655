<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />

    <m-card v-if="!initializing" :title="$t('shared.edit')">
      <base-form v-model="model" :permissions="permissions" />

      <template #actions>
        <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      return this.$api.org.roles.getById(this.id).then((res) => {
        this.model = res
        this.$setBreadcrumbs([
          {text: this.$t('nav.roles'), to: '/org/roles'},
          {text: res.name, disabled: true}
        ])
      })
    },
    submit() {
      this.progressing = true
      return this.$api.org.roles
        .update(this.id, this.model)
        .then(() => {
          this.$showSuccess(this.$t('roles.roleUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
